import axios, { AxiosRequestConfig } from 'axios'
import qs from 'qs'

axios.defaults.paramsSerializer = params => qs.stringify(params)

export default class Api {
  baseUrl: string
  searchCar
  searchCarByStrictNumber
  startPayment
  sessionInfo
  payment
  areas
  subscriptions
  subscriptionInfo
  startSubscriptionPayment
  purchasedSubscription
  saveSubscriptionFields
  applyDiscount
  checkDiscountPage
  events
  eventInfo
  startEventPayment
  finesInfo
  applyReceiptDiscount
  paymentReceipt
  partnerPromotions

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl
    this.areas = this._get('web/areas/:areaId')
    this.searchCar = this._get('web/areas/:areaId/stages')
    this.searchCarByStrictNumber = this._get('web/areas/:areaId/stages/strict')
    this.sessionInfo = this._get('web/stages/:stageId')
    this.startPayment = this._post('web/stages/:stageId/bill')
    this.payment = this._get('web/payments/:token')
    this.subscriptions = this._get('web/areas/:areaId/subscriptions?filter[show_on_sales_page]=true&filter[only_can_buy]=true')
    this.subscriptionInfo = this._get('web/subscriptions/:subscriptionId')
    this.startSubscriptionPayment = this._post('web/subscriptions/:subscriptionId/bill')
    this.purchasedSubscription = this._get('web/client-subscriptions/purchased/:token')
    this.saveSubscriptionFields = this._post('web/client-subscriptions/:token/additional-fields')
    this.applyDiscount = this._post('web/stages/apply-discount')
    this.checkDiscountPage = ({ token, areaId }: { token: string; areaId: string }) =>
      this._get(
        'web/renters/check?token=:token&area_slug=:area'
          .replace(':token', token)
          .replace(':area', areaId),
      )
    this.events = this._get('web/areas/:areaId/events')
    this.eventInfo = this._get('web/events/:eventId')
    this.startEventPayment = this._post('web/events/:eventId/bill')
    this.finesInfo = this._get('web/fines/search-by-stage/:stageId')
    this.applyReceiptDiscount = this._post('web/stages/:stageId/discounts/receipt')
    this.paymentReceipt = this._get('web/payments/receipt-link/:externalOrderNumber')
    this.partnerPromotions = this._get('partner-promotions')
  }

  private fullUrlWithParams(url: string, params?: any) {
    // parse variables inside urls: web/stages/:stageId/bill
    const variables = url.split('/').filter(w => w.startsWith(':'))
    const restParams = { ...params }

    const fullUrl = variables.reduce((acc, item) => {
      delete restParams[item]
      return acc.replace(item, params[item])
    }, `${this.baseUrl}/${url}`)

    return [fullUrl, restParams]
  }

  _get(url: string) {
    return (query: AxiosRequestConfig = {}) => {
      const { cancelToken = axios.CancelToken.source().token, params = {} } = query
      const [fullUrl, restParams] = this.fullUrlWithParams(url, params)

      return axios
        .get(fullUrl, {
          cancelToken,
          params: restParams,
        })
        .then(response => response.data)
        .catch(error => {
          const config = error.response.config
          const { method, url, params } = config
          return {
            result: 'error',
            statusCode: error.response.status,
            config: { method, url, params },
            data: error.response.data,
          }
        })
    }
  }

  _post(url: string) {
    return (query: AxiosRequestConfig = {}) => {
      const { cancelToken = axios.CancelToken.source().token, data } = query
      const [fullUrl, restData] = this.fullUrlWithParams(url, data)

      return axios
        .post(fullUrl, restData, {
          cancelToken,
        })
        .then(response => {
          return response.data
        })
        .catch(error => {
          return {
            ...error.response.data,
            result: 'error',
            statusCode: error.response.status,
          }
        })
    }
  }

  _postFormData(url: string) {
    return (formdata: FormData) => {
      const [fullUrl] = this.fullUrlWithParams(url, {})

      return axios
        .post(fullUrl, formdata, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(response => response.data)
        .catch(error => ({
          result: 'error',
          statusCode: error.response.status,
          ...error.response.data,
        }))
    }
  }
}