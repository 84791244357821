import { Title, Container, Paragraph, Button, FixedBottom, HalvaBanner } from '@/components'
import cn from 'classnames'
import styles from './PaymentResult.module.scss'
import getFullDate from '@/helpers/getFullDate'
import getLinkHref from '@/helpers/getLinkHref'
import { getNumberLink } from '@/helpers/getNumberLink'
import wordEnd from '@/helpers/wordEnd'
import { useRouter } from 'next/router'
import { Area, AreaInfoResponseData } from '@/store/slices/stagesSlice/interfaces'
import { QRCodeCanvas } from 'qrcode.react'

export default function PaymentSuccessPage({
  paidUntil,
  area,
  extraPay,
  discountMinutes,
  onClickGoMain,
  carNumber,
}: {
  paidUntil?: string | null
  area:
    | {
        id: string
        phone?: string
        customising?: { button_back_color: string | null; logo_circle: string | null }
      }
    | null
    | AreaInfoResponseData
    | Area
  onClickGoMain?: () => void
  extraPay?: number
  discountMinutes?: number
  carNumber?: string
}) {
  const router = useRouter()
  const date = getFullDate(paidUntil || '', true)
  const isDiscountPage = router.asPath.includes('discount')

  const text = date
    ? `\nдо ${date.day}.${date.month}.${date.year} ${date.hours}:${date.minutes}:${date.seconds}`
    : ''

  const phoneLink = getNumberLink(area?.phone)
  const areaId = area?.id ? area?.id : router.query.areaId
  const areaHref = getLinkHref({ type: 'area', data: { id: areaId } })
  const url = window.location.href.split('/').slice(0, 4).join('/')

  const onClick = () => {
    onClickGoMain ? onClickGoMain() : router.push(areaHref)
  }

  return (
    <section className={styles.section}>
      <Container className={styles.container}>
        {!isDiscountPage && <HalvaBanner />}
        <div className={styles.container__inner}>
          <div>
            <img
              className={styles.sign__Error__icon}
              src="/images/payment-success.svg"
              alt="logo"
              width={87}
              height={87}
            />

            <Title align="center">
              Успешно!
              {isDiscountPage && carNumber && Boolean(extraPay) && (
                <div className={styles.subtitle}>
                  {`Скидка на ${discountMinutes} минут предоставлена для транспортного средства ${carNumber}.`}
                </div>
              )}
            </Title>

            {isDiscountPage && carNumber && !extraPay && (
              <Paragraph
                align="center"
                className={cn(styles.section__descr, {
                  [styles.section__descr_isDiscountPage]: isDiscountPage,
                })}
              >
                Скидка применена для транспортного средства {carNumber}.
              </Paragraph>
            )}
          </div>

          <div className={styles.section__text_content}>
            {extraPay ? (
              <div className={styles.extraPay}>
                <div>
                  <Paragraph className={styles.section__text_secondary}>
                    Дополнительно к оплате:
                  </Paragraph>
                  <Paragraph
                    className={cn(
                      styles.section__text_secondary,
                      styles['section__text_secondary--dark'],
                      { [styles['section__text_secondary--isDiscountPage']]: isDiscountPage },
                    )}
                  >
                    {extraPay} {wordEnd(+extraPay, ['рубль', 'рубля', 'рублей'])}
                  </Paragraph>
                </div>
                <div className={styles.qrcodeBlock}>
                  <span>
                    Оплатить можно через QR-код или терминалы расположенные на территории парковки
                  </span>

                  <QRCodeCanvas
                    value={url}
                    size={165}
                    bgColor={'#ffffff'}
                    fgColor={'#000000'}
                    level={'Q'}
                    includeMargin={false}
                    imageSettings={{
                      src: area?.customising?.logo_circle ?? '/images/logo.png',
                      x: undefined,
                      y: undefined,
                      height: 45,
                      width: 45,
                      excavate: true,
                    }}
                  />
                </div>
              </div>
            ) : (
              <>
                <Paragraph
                  align="center"
                  className={cn(styles.section__descr, {
                    [styles.section__descr_isDiscountPage]: isDiscountPage,
                  })}
                >
                  Вы можете выехать с парковки{text}.
                </Paragraph>
                <Paragraph
                  align="center"
                  className={cn(styles.section__text, {
                    [styles.section__text_isDiscountPage]: isDiscountPage,
                  })}
                >
                  Держите номер авто чистым для автоматического открытия шлагбаума.
                </Paragraph>
              </>
            )}
          </div>
        </div>

        <FixedBottom isHide={true} isDiscountPage={isDiscountPage}>
          <div className={cn(styles.buttons, { [styles.buttons_isDiscountPage]: isDiscountPage })}>
            <div className={styles.button}>
              <Button
                component="button"
                className={styles.button}
                onClick={onClick}
                background={area?.customising?.button_back_color}
              >
                На главную
              </Button>
            </div>

            {phoneLink &&
              (isDiscountPage ? (
                <div className={styles.footerMessage}>
                  Номер технической поддержки сервиса HippoParking:{' '}
                  <a className={styles.footerMessage_isDesktop} href={'tel:88002003320'}>
                    {'8 800 200 33 20'}
                  </a>
                  <a className={styles.footerMessage_isTablet}>{'8 800 200 33 20'}</a>
                </div>
              ) : (
                <Button component="a" href={phoneLink} className={styles.button} isTransparent>
                  Обратиться в поддержку
                </Button>
              ))}
          </div>
        </FixedBottom>
      </Container>
    </section>
  )
}
